import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import './OtpVerify.css';
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resendOtp, verifyOtp } from '../../store/actions/user.actions';
import Loader from '../../components/Loader/Loader';

const OtpVerify = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const user = useSelector((state) => state.user);
    const { loading } = useSelector((state) => state.user);

    useEffect(() => {
        if (!user.userData) {
            navigate('/register');
            reset()
        }
    }, []);

    const onSubmit = (data) => {
        if (!user) return;
        dispatch(verifyOtp(user.userData.email, data.code, navigate));
    };

    const sendOtp = () => {
        dispatch(resendOtp(user.userData.email))
        reset()
    }

    return (
        <>
            <Header isHeaderShow={false} />
            <div className='forgetPassword-container'>
                {loading && <Loader />}
                <div className="box">
                    <h1>Enter one-time code</h1>
                    {user && <p>Check {user?.userData?.email} for a verification code.</p>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-md-12 mt-3">
                            <input
                                type="text"
                                className={`form-control ${errors.code ? 'is-invalid' : ''}`}
                                placeholder='one-time code'
                                {...register('code', { required: true, pattern: /^.{6}$/ })}
                            />
                            {errors.code && errors.code.type === "required" && (
                                <p className="invalid-feedback">Please enter a 6-characters code</p>
                            )}
                            {errors.code && errors.code.type === "pattern" && (
                                <p className="invalid-feedback">Code should be 6-characters code</p>
                            )}
                            <p style={{ color: "#878BB7", cursor: 'pointer', fontSize: '0.85rem', marginTop: '5px', border: 'none' }} onClick={sendOtp} >
                                Resend code
                            </p>
                        </div>

                        <div className="col-md-12 mt-4">
                            <button className="btn btn-primary btn-purple" type="submit" style={{ width: "100%" }}>Submit</button>
                        </div>
                        <p className='mt-3'>
                            if you don’t see a code in your inbox, check your spam folder . if it’s not there, the email address may not be confirmed. or it may not match an existing Account.
                        </p>

                        {/* <p style={{ color: "#878BB7" }}>
                            Can’t access this email?
                        </p> */}

                    </form>
                </div>
            </div>
        </>

    );
};

export default OtpVerify;
