import React, { useState } from 'react'
import Loader from '../../components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChangePasswordAction } from '../../store/actions/user.actions';
import { passwordPattern } from '../../constant/app-constants';
import eye from '../../assets/images/eye.png'
import hide from '../../assets/images/hide.png'
import { noLeadingOrTrailingSpaces } from '../../Utils/validators';
import { useMediaQuery } from '@mui/material';

const ChangePassword = () => {


    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const { loading } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false); // State to track whether password is shown or hidden
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to track whe
    const password = watch('password')
    const location = useLocation();
    const navigate = useNavigate();
    const isMobileScreen = useMediaQuery('(max-width:600px)')

    const onSubmit = (data) => {
        // Handle form submission
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        dispatch(ChangePasswordAction(token, data.password, navigate))
        reset()
    };

    return (
        <>
            <div className='forgetPassword-container'>
                {loading && <Loader />}
                <div className="box" style={{ minWidth: isMobileScreen ? '90%' : '600px' }} >
                    <h1>Reset Password</h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-md-12 mt-3">
                            <div className="input-group">
                                <input
                                    type={showPassword ? 'text' : 'password'} // Conditional rendering based on showPassword state
                                    className="form-control"
                                    placeholder="Password"
                                    {...register('password', { required: true, minLength: 8, pattern: passwordPattern })}
                                />
                                <button className='append-btn' type='button' onClick={() => setShowPassword(!showPassword)}>{showPassword ?
                                    <img src={eye} alt="" srcset="" />
                                    : <img src={hide} alt="" srcset="" />
                                }</button>
                            </div>

                            {errors.password && errors.password.type === "required" && (
                                <p className="text-danger">Password is required</p>
                            )}
                            {errors.password && errors.password.type === "minLength" && (
                                <p className="text-danger">Password must be at least 8 characters long</p>
                            )}
                            {errors.password && errors.password.type === "pattern" && (
                                <p className="text-danger">Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character</p>
                            )}
                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="input-group">
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'} // Conditional rendering based on showPassword state
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    {...register('confirmPassword', {
                                        required: true, minLength: 8, validate: value => value === password || 'The passwords do not match'
                                    })}
                                />
                                <button className='append-btn' type='button' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ?
                                    <img src={eye} alt="" srcset="" />
                                    : <img src={hide} alt="" srcset="" />
                                }</button>
                            </div>
                            {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                                <p className="text-danger">Confirm Password is required</p>
                            )}
                            {errors.confirmPassword && errors.confirmPassword.type === "minLength" && (
                                <p className="text-danger">Confirm Password must be at least 8 characters long</p>
                            )}
                            {errors.confirmPassword && errors.confirmPassword.type === "validate" && (
                                <p className="text-danger">The passwords do not match</p>
                            )}
                        </div>

                        <div className="col-md-12 mt-4">
                            <button className="btn btn-primary btn-purple" type="submit" style={{ width: "100%" }}>Submit</button>
                        </div>
                        {/* 
                        <div className="col-md-12 mt-2">
                            <Link to='/login'>
                                <button className="btn btn-primary btn-white" type="button" style={{ width: "100%" }}>Back</button>
                            </Link>
                        </div> */}
                    </form>
                </div>
            </div>
        </>)
}

export default ChangePassword