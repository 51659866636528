import React from 'react'
import upload from '../../assets/images/Uplod.png'
import trashCan from '../../assets/images/TrashCan.png'
import download from '../../assets/images/Download.png'
import uploadBlack from '../../assets/images/uploadblack.png'
import thumbnail from '../../assets/images/Thumbnails.png'
import list from '../../assets/images/List.png'
import './Upload.css'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { noLeadingOrTrailingSpaces } from '../../Utils/validators'

const Upload = ({ handleNewFolder, toggleDrapDrop, deleteSelectedItems, downloadSelectedItems, changeView, isGridView }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [folderName, setFolderName] = React.useState('');

    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        reset()
    };

    const handleFolderNameChange = (event) => {
        setFolderName(event.target.value);
    };

    const handleCreateFolder = (folder) => {
        handleNewFolder(folder.replace(/\s+$/, ''));
        handleDialogClose();
        setFolderName('')
        reset()
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSubmit = data => {
        handleCreateFolder(data.folderName);
        reset(); // Reset the form after submission
      };


    return (
        <div className='upload'>
            <div>
                <button className='upload-btn' onClick={toggleDrapDrop} >
                    <img className='bi' src={upload} alt="" />
                    Upload Drone Data to Sync for DJI
                </button>
            </div>

            <div className='btn-box'>
                <div>
                    <button className='btn-icon' onClick={deleteSelectedItems} >
                        <img src={trashCan} alt="" />
                    </button>
                    <button className='btn-icon' onClick={downloadSelectedItems}>
                        <img src={download} alt="" />
                    </button>
                    <button className='btn-icon' onClick={changeView}>
                        {isGridView ? <img src={thumbnail} alt="" /> : <img src={list} alt="" />}
                    </button>
                </div>
                <button className='white'
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <img className='bi' src={uploadBlack} alt="" /> Upload Data
                </button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => { handleClose(); toggleDrapDrop(); }} className='white'>
                        File/Folder Upload
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); handleDialogOpen(); }} className='white'>
                        New Folder
                    </MenuItem>
                </Menu>

            </div>
            <Dialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                sx={{ '& .MuiDialog-paper': { minWidth: '400px' } }}
            >
                <DialogTitle>Create New Folder</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <input
                            type="text"
                            name="folderName"
                            className='form-control'
                            defaultValue={folderName}
                            {...register('folderName', { required: 'Folder name is required', validate:noLeadingOrTrailingSpaces })}
                        />
                        {errors.folderName && <span className='text-danger' >{errors.folderName.message}</span>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} style={{ color: 'black' }}>Cancel</Button>
                        <Button type="submit" style={{ color: 'black' }}>Create</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div >
    )
}

export default Upload