import React, { useEffect, useRef, useState } from 'react';
import './ProfileDailog.css';
import { Avatar, Dialog } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateProfile } from '../../store/actions/user.actions';
import Camera from '../../assets/images/Camera.png'
import { noLeadingOrTrailingSpaces, lettersAndNumbersOnly } from '../../Utils/validators';
import Profile from '../../assets/images/Profile.png'

const ProfileDailog = (props) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch()
    const [profileImg, setProfileImg] = useState(null)
    const [showImg, setShowImg] = useState(null);

    const { onClose, open } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const user = useSelector((state) => state.user.user)

    useEffect(() => {
        if (profileImg) {
            const reader = new FileReader();
            reader.onload = () => {
                setShowImg(reader.result);
            };
            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };
            reader.readAsDataURL(profileImg);
        }
    }, [profileImg])

    useEffect(() => {
        if (user) {
            setValue("firstName", user.first_name);
            setValue("lastName", user.last_name);
            setValue("aboutMe", user.about_user ?? "");
            setShowImg(user.profile_image ?? Profile)
        }
    }, [setValue, user, open])


    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setProfileImg(file);
    };

    const handleClose = () => {
        if (user.profileImg === null) {
            setShowImg(null)
        }
        onClose()
        reset()
    }

    const onSubmit = (data) => {
        // Handle form submission
        const formdata = new FormData();
        formdata.append('first_name', data.firstName);
        formdata.append('last_name', data.lastName);
        formdata.append('about_user', data.aboutMe)
        if (profileImg) {
            formdata.append('profile_image', profileImg)
        }

        dispatch(UpdateProfile(formdata))
        onClose();
    };

    return (
        <Dialog open={open}>
            <div className="card card-div">
                <div className="card-body">
                    <div className="text-end cross-div ">
                        <button className="cross-btn text-end" onClick={handleClose}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="30"
                                height="30"
                                fill="rgba(0,0,0,1)"
                            >
                                <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
                            </svg>

                        </button>
                    </div>
                </div>
                <div className="px-5 py-2">
                    <h2 className="text-center">Account Details</h2>
                    <div className="d-flex justify-content-center py-2">
                        <div className="py-3 img-div text-center" onClick={handleDivClick}>
                            <div>
                                {showImg ? (
                                    <div style={{ position: 'relative' }}>
                                        <Avatar
                                            src={showImg ?? Profile}
                                            sx={{ width: 120, height: 120, filter: 'opacity(0.7)' }}
                                        />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                display: "flex",
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <img src={Camera} alt="" style={{ width: "30px", height: "30px" }} />
                                            <span style={{ color: 'white', fontSize: 9 }}>

                                                Click to change image</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ position: 'relative' }}>
                                        <svg
                                            className="mt-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="36"
                                            height="36"
                                            fill="rgba(255,255,255,0.7)"
                                            style={{ filter: 'opacity(0.7)' }}
                                        >
                                            <path d="M9 3H15L17 5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V6C2 5.44772 2.44772 5 3 5H7L9 3ZM12 19C15.3137 19 18 16.3137 18 13C18 9.68629 15.3137 7 12 7C8.68629 7 6 9.68629 6 13C6 16.3137 8.68629 19 12 19ZM12 17C9.79086 17 8 15.2091 8 13C8 10.7909 9.79086 9 12 9C14.2091 9 16 10.7909 16 13C16 15.2091 14.2091 17 12 17Z"></path>
                                        </svg>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                display: "flex",
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <img src={Camera} alt="" style={{ width: "30px", height: "30px" }} />

                                            <span style={{ color: 'white', fontSize: 9 }}>Click to change image</span>
                                        </div>
                                    </div>
                                )}
                            </div>


                            <input type="file" accept=".jpeg, .jpg, .png, .webp" onChange={handleFileChange} style={{ display: 'none' }} ref={fileInputRef} />

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="firstName">First Name</label>
                            <input type="text" id="firstName" className="form-control" {...register("firstName", {
                                required: "First name is required", validate: {
                                    noLeadingOrTrailingSpaces,
                                    lettersAndNumbersOnly
                                }
                            })} />
                            {errors.firstName && <p className="text-danger">{errors.firstName.message}</p>}
                        </div>
                        <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="lastName">Last Name</label>
                            <input type="text" id="lastName" className="form-control" {...register("lastName", {
                                required: "Last name is required", validate: {
                                    noLeadingOrTrailingSpaces,
                                    lettersAndNumbersOnly
                                }
                            })} />
                            {errors.lastName && <p className="text-danger">{errors.lastName.message}</p>}
                        </div>
                        <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="aboutMe">About me</label>
                            <textarea id="aboutMe" className="form-control" {...register("aboutMe", {
                                // required: "About me is required",
                                validate: {
                                    noLeadingOrTrailingSpaces
                                }
                            })} />
                            {errors.aboutMe && <p className="text-danger">{errors.aboutMe.message}</p>}
                        </div>
                        <div className="d-flex justify-content-center px-5">
                            <button className="btn btn-lg done-btn rounded-pill px-5 mb-3">
                                <span className="px-5 text-white">Done</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    );
};

export default ProfileDailog;
