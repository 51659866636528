import { Breadcrumbs, Link, Typography } from '@mui/material'
import BigLock from '../../../../assets/images/BigLock.png'
import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
      <div className='mb-3'>
        <img src={BigLock} alt="" srcset="" />
      </div>

      <Breadcrumbs aria-label="breadcrumb" sx={{ '& .MuiBreadcrumbs-separator': { fontSize: '30px' } }}>
        <Link underline="hover" color="inherit" >
          <Typography variant='h4'>Settings</Typography>
        </Link>
        <Typography color="text.primary" variant='h4'>Privacy Policies</Typography>
      </Breadcrumbs>
      <div className='mt-3'>

        {/* <h3>SyncForDJI Privacy Policy</h3> */}
        <p>At SyncForDJI, we are committed to protecting the privacy and security of your personal information and drone data. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our storage management dashboard.</p>

        <h5>Information We Collect</h5>
        <ul>
          <li><strong>Account Information:</strong> When you create an account, we collect your name, email address, and optionally, other profile information to personalize your experience.</li>
          <li><strong>Drone Data:</strong> We securely store and manage the drone footage, images, and other data you upload to our platform.</li>
          <li><strong>Usage Information:</strong> We collect information about how you interact with our dashboard, such as log data, device information, and usage patterns.</li>
        </ul>

        <h5>How We Use Your Information</h5>
        <ul>
          <li><strong>Provide Services:</strong> We use your information to deliver and maintain our storage management services, including storing and organizing your drone data.</li>
          <li><strong>Communications:</strong> We may use your contact information to send you important notifications, updates, or promotional materials related to our services.</li>
          <li><strong>Improve and Customize:</strong> We analyze usage data to improve our dashboard's functionality, tailor user experiences, and develop new features.</li>
          <li><strong>Security:</strong> We employ industry-standard security measures to protect your data against unauthorized access, disclosure, or alteration.</li>
        </ul>

        <h5>Data Sharing and Disclosure</h5>
        <ul>
          <li><strong>Third-Party Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in delivering our services, such as cloud hosting providers or analytics services.</li>
          <li><strong>Legal Compliance:</strong> We may disclose your information to comply with legal obligations, enforce our policies, respond to legal requests, or protect our rights, property, or safety.</li>
        </ul>

        <h5>Your Choices and Rights</h5>
        <ul>
          <li><strong>Access and Control:</strong> You can access, update, or delete your account information and drone data at any time through the dashboard settings.</li>
          <li><strong>Communication Preferences:</strong> You can manage your communication preferences and opt-out of promotional emails or notifications.</li>
          <li><strong>Data Retention:</strong> We retain your information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</li>
        </ul>

        <h5>Privacy of Minors</h5>
        <p>Our services are not intended for use by individuals under the age of 18. We do not knowingly collect personal information from minors without parental consent.</p>

        <h5>Updates to this Privacy Policy</h5>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website or through other communication channels.</p>

        <h5>Contact Us</h5>
        <p>If you have any questions or concerns about our Privacy Policy or data practices, please contact us at [contact email].</p>
      </div>
    </>
  )
}

export default PrivacyPolicy