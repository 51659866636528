import React from 'react'
import './Paymentinfo.css'

const PaymentInfo = ({ head, value }) => {
    return (
        <div className='payment-info'>
            <h5>{head} </h5>
            <p>{value}</p>
        </div>
    )
}

export default PaymentInfo