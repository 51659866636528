import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Profile from '../../assets/images/Profile.png'
import Dropdown from '../../assets/images/dropdown.png'
import SingOut from '../../assets/images/SignOut.png'
import VoiletHelp from '../../assets/images/VoiletHelp.png'
import VoiletGear from '../../assets/images/VoiletGear.png'
import History from '../../assets/images/History.png'
import Lock from '../../assets/images/Lock.png'
import './ProfileMenu.css'
import { Link, useNavigate } from 'react-router-dom';
import { Logout } from '../../store/reducers/user.reducers';
import { useSelector } from 'react-redux';
import Loader from '../Loader/Loader';

const ProfileMenu = () => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [isHovered, setIsHovered] = React.useState(false);
    const user = useSelector((state) => state.user.user)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        localStorage.removeItem("token")
        handleClose()
        navigate('/login')
        Logout()
    }

    return (
        <div className='profile'>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{ marginRight: '1vmax' }}
                >
                    <Avatar sx={{ width: 32, height: 32 }} src={user.profile_image ?? Profile} />
                    {(isHovered || anchorEl) && <img src={Dropdown} style={{ padding: '5px' }} />}
                    {/* </Avatar> */}
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        // '& .MuiAvatar-root': {
                        //     width: 32,
                        //     height: 32,
                        //     ml: -0.5,
                        //     mr: 1,
                        // },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    <Avatar sx={{ width: 60, height: 60 }} src={user.profile_image ?? Profile} />
                    <div style={{ padding: '1vmax' }}>
                        <h5 className='text'>{`${user.first_name} ${user.last_name}`}</h5>
                        {/* <h5 className='text-center text'>Bio</h5> */}
                    </div>
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 1 }}>
                    <Link to='/dashboard/profile'>
                        <button className='porfile-btn'>
                            View Profile
                        </button>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose} >
                    <Link to='/dashboard/changePassword' className='profile-links'>
                        <ListItemIcon>
                            <img src={Lock} alt="" />
                        </ListItemIcon>
                        Change Password
                    </Link>
                </MenuItem>
                    <MenuItem onClick={handleClose} >
                        <Link to='/dashboard/payment-history' className='profile-links'>
                            <ListItemIcon>
                                <img src={History} alt="" />
                            </ListItemIcon>
                            Payment History
                        </Link>
                    </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to='/dashboard/privacy-policies' className='profile-links' role='button'>
                        <ListItemIcon>
                            <img src={VoiletGear} alt="" />
                        </ListItemIcon>
                        Privacy and Policies
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose} >
                    <Link to='/dashboard/help' className='profile-links'>
                        <ListItemIcon>
                            <img src={VoiletHelp} alt="" />
                        </ListItemIcon>
                        Help
                    </Link>
                </MenuItem>
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <img src={SingOut} alt="" />
                    </ListItemIcon>
                    Sign Out
                </MenuItem>

            </Menu>
        </div>
    )
}

export default ProfileMenu