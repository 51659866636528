import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch } from 'react-redux';
import { saveDroneData } from '../../store/actions/user.actions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

export default function MultipleSelect({ drones, selectedDrones }) {
    const [droneName, setdroneName] = React.useState((selectedDrones && JSON.parse(selectedDrones)) ?? []);
    const dispatch = useDispatch()

    const handleChange = (event) => {
        let {
            target: { value },
        } = event;
        setdroneName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.shift().split(',') : value,
        );
    };

    const handleSubmit = () => {
        dispatch(saveDroneData(droneName))
    }


    return (
        <div>
            <from >
                <FormControl sx={{
                    m: 1,
                    '&.MuiSelect-select:focus, &.MuiSelect-select:hover,&.MuiSelect-select:active ': {
                        borderColor: 'black', // Replace with your color
                        outline: 'none'
                    },
                    '& .MuiSelect-placeholder': {
                        opacity: 1, // Ensure placeholder is fully visible
                    },
                }} fullWidth  >
                    <InputLabel id="demo-multiple-name-label">Drones</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={droneName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Drones" />}
                        placeholder='Select Drone'
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {drones.map((drone) => (
                            <MenuItem key={drone.id} value={drone.drone_name}>
                                <Checkbox checked={droneName.indexOf(drone.drone_name) > -1} />
                                <ListItemText primary={drone.drone_name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <button onClick={handleSubmit} type='submit' className='closebtn ms-2' >Save</button>
            </from>
        </div>
    );
}
