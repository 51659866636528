import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import BigLock from '../../../../assets/images/BigLock.png'
import SmallLock from '../../../../assets/images/SmallLock.png'
import './ChangePassword.css'
import eye from '../../../../assets/images/eye.png'
import hide from '../../../../assets/images/hide.png'
import closeEye from '../../../../assets/images/closeEye.png'
import { Breadcrumbs, Typography, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserPassword } from '../../../../store/actions/user.actions';
import { passwordPattern } from '../../../../constant/app-constants';
import Loader from '../../../../components/Loader/Loader';
// import {  } from 'react-router-dom';

const ChangePassword = () => {
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false); // State to track whether password is shown or hidden
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to track whether password is shown or hidden

    const dispatch = useDispatch()
    const loading = useSelector((state) => state.user.loading)

    const { register, handleSubmit, formState: { errors }, setError, watch, reset } = useForm();

    const onSubmit = async (data) => {
        dispatch(changeUserPassword(data.newPassword, data.currentPassword));
        reset()
    };

    return (
        <div className="reset-password-form">
            {loading && <Loader />}
            <div className='mb-3'>
                <img src={BigLock} alt="" srcset="" />
            </div>
            {/* <h2>Settings/Reset Password</h2>
             */}

            <Breadcrumbs aria-label="breadcrumb" sx={{ '& .MuiBreadcrumbs-separator': { fontSize: '30px' } }}>
                <Link underline="hover" color="inherit">
                    <Typography variant='h4'>Settings</Typography>
                </Link>
                <Typography color="text.primary" variant='h4'>Change Password</Typography>
            </Breadcrumbs>


            <div className='col-md-6 row mt-3'>
                <p>
                    To change your password, please fill in the fields below.
                    Your password must contain at least 8 characters, it must also include
                    at least one Upper case letter, one lower case letter, one number abd one special character.
                </p>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className='mb-3'>
                        <label className='form-label' htmlFor="current-password">Enter your current password to verify your identity.</label>
                        <div className='input-with-icon'>
                            <span className="input-icon">
                                <img src={SmallLock} alt="" />
                            </span>
                            <input
                                type={showOldPassword ? "text" : "password"}
                                className="form-control"
                                style={{ paddingLeft: '30px' }}
                                id="current-password"
                                {...register('currentPassword', { required: 'Current Password is required' })}
                            />
                            <span className="input-icon-last">
                                <button type='button' onClick={() => setShowOldPassword(!showOldPassword)} >
                                    {showOldPassword ?
                                        <img src={eye} alt="" srcset="" />
                                        : <img src={hide} alt="" srcset="" />
                                    }
                                </button>
                            </span>
                        </div>

                        {errors.currentPassword && <p className="text-danger">{errors.currentPassword.message}</p>}

                    </div>
                    <div className='mb-3'>
                        <label className='form-label' htmlFor="new-password">Create a new password. Make sure it's at least 8 characters long and
                            includes a mix of letters, numbers, and symbols.</label>
                        <div className='input-with-icon'>
                            <span className="input-icon">
                                <img src={SmallLock} alt="" />
                            </span>
                            <input
                                type={showPassword ? 'text' : "password"}
                                className="form-control"
                                id="new-password"
                                style={{ paddingLeft: '30px' }}
                                {...register('newPassword',
                                    { required: true, minLength: 8, pattern: passwordPattern })}
                            />
                            <span className="input-icon-last">
                                <button type='button' onClick={() => setShowPassword(!showPassword)}>
                                    {/* <img src={closeEye} alt="" /> */}
                                    {showPassword ?
                                        <img src={eye} alt="" srcset="" />
                                        : <img src={hide} alt="" srcset="" />
                                    }
                                </button>
                            </span>
                        </div>
                        {errors.newPassword && errors.newPassword.type === "required" && (
                            <p className="text-danger">New password is required</p>
                        )}
                        {errors.newPassword && errors.newPassword.type === "minLength" && (
                            <p className="text-danger">New password must be at least 8 characters long</p>
                        )}
                        {errors.newPassword && errors.newPassword.type === "pattern" && (
                            <p className="text-danger">New password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character</p>
                        )}
                        {/* {errors.newPassword && <p className="text-danger">{errors.newPassword.message}</p>} */}
                    </div>

                    <div className='mb-3'>
                        <label className='form-label' htmlFor="confirm-password">Confirm New Password: Re-enter your new password to confirm it matches.</label>
                        <div className='input-with-icon'>
                            <span className="input-icon">
                                <img src={SmallLock} alt="" />
                            </span>
                            <input
                                type={showConfirmPassword ? `text` : 'password'}
                                className="form-control"
                                id="confirm-password"
                                style={{ paddingLeft: '30px' }}
                                {...register('confirmPassword', {
                                    required: 'Confirm Password is required',
                                    validate: (value) => value === watch('newPassword') || 'Passwords do not match',
                                })}
                            />
                            <span className="input-icon-last">
                                <button type='button' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    {showConfirmPassword ?
                                        <img src={eye} alt="" srcset="" />
                                        : <img src={hide} alt="" srcset="" />
                                    }
                                </button>
                            </span>
                        </div>

                        {errors.confirmPassword && <p className="text-danger">{errors.confirmPassword.message}</p>}
                    </div>
                    <button type="submit" className='closebtn'>Submit</button>
                </form>
            </div>
        </div>
    )
}

export default ChangePassword