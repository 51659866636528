import React from 'react';
import './Checkout.css';
import { Grid, Typography, Button } from '@mui/material';
import PaymentInfo from '../../components/Payment-info/PaymentInfo';
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import ConfirmPaymentDailog from '../../components/ConfirmPaymentDailog/ConfirmPaymentDailog';
import { PayPalButtons } from '@paypal/react-paypal-js'; // Import PayPal buttons component\
import { toast } from 'react-toastify';
import { OpenPaymentDialog } from '../../store/reducers/user.reducers';
import { SubscriptionDetails } from '../../store/actions/user.actions';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CheckoutForm = ({ selectedPlan }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.user)

    const createSubscription = (data, actions) => {
        // Create subscription with selected plan ID
        return actions.subscription.create({
            plan_id: selectedPlan.planId
        }).catch(error => {
            toast.error("Something went wrong")
            console.error('Subscription creation error:', error);
            throw error; // Rethrow the error to handle it further
        });;
    };

    const onApprove = (data, actions) => {
        // Handle successful subscription approval
        dispatch(OpenPaymentDialog(false))
        dispatch(SubscriptionDetails(data));
    };

    const onError = (err) => {
        dispatch(OpenPaymentDialog(true))
        console.error('Subscription error:', err);
    };

    const handleGoBack = () => { navigate(-1); };

    return (
        <>
            <Header isHeaderShow={false} />
            <div className='payment_method px-3 py-3 '>
                {loading && <Loader />}
                <div className="col-md-12 payment_heading">
                    <h1>Payment Method</h1>
                    <p className='text-muted text-center'>Choose a payment option and fill in the requested information</p>
                </div>
                {/* <form onSubmit={handleSubmit} className='strip-form'> */}
                <Grid container spacing={2} justifyContent="space-evenly">
                    <Grid item xs={12} md={4} style={{ marginBottom: '16px', padding: '2vmax', background: '#f3f4fd' }}>
                        <div>
                            <Button
                                onClick={handleGoBack}
                                startIcon={<ArrowBackIcon />}
                                sx={{ color: 'black' }}
                            >Choose Another Plan
                            </Button>
                            <div><h3 className='text-dark'>Payment settings</h3></div>

                            <h4 className='mt-4'>{selectedPlan.planName}</h4>
                            <p className='text-muted'>With limited access to the template catalog and basic lorem ipsum</p>
                            <hr className='mb-3' />
                            <PaymentInfo head='Transaction date:' value='30/04/2024' />
                            <PaymentInfo head='Hour:' value='12:48PM' />

                            <hr style={{ borderTop: '2px dashed #00000080', margin: '1vmax 0' }} />

                            <PaymentInfo head='Subtotal:' value={`$${selectedPlan.planAmount} USD`} />
                            <PaymentInfo head='Discount:' value='$0.00 USD' />
                            <hr className='mb-3' />

                            {/* <button className='btn btn-dark form-control mt-4 btn-design' type="submit">Confirm</button> */}
                            <PayPalButtons
                                createSubscription={createSubscription}
                                onApprove={onApprove}
                                onError={onError}
                            />
                        </div>
                    </Grid>
                </Grid>
                {/* </form> */}
            </div>
            <div>
                <ConfirmPaymentDailog />
            </div>
        </>

    )
}

export default CheckoutForm;