import { Breadcrumbs, Link, Typography } from '@mui/material'
import React from 'react'
import BigLock from '../../../../assets/images/BigLock.png'
import help from '../../../../constant/help.json'

const Help = () => {
    return (
        <>
            <div className='mb-3'>
                <img src={BigLock} alt="" srcset="" />
            </div>

            <Breadcrumbs aria-label="breadcrumb" sx={{ '& .MuiBreadcrumbs-separator': { fontSize: '30px' } }}>
                <Link underline="hover" color="inherit">
                    <Typography variant='h4'>Settings</Typography>
                </Link>
                <Typography color="text.primary" variant='h4'>Help</Typography>
            </Breadcrumbs>
            <div className='mt-4'>
                <h5>
                    How Can We Assist You?
                </h5>
                <ul>
                    {
                        help.sections.map((item) => {
                            return (
                                <li>
                                    <b>{item.title} : </b> {item.description}
                                </li>
                            )
                        })
                    }
                </ul>
                <h5>
                    Popular Articles
                </h5>
                <ul>
                    {
                        help.popularArticles.map((item) => {
                            return (
                                <li>
                                    <b>{item.title} : </b> {item.description}
                                </li>
                            )
                        })
                    }
                </ul>
                <h5>
                    Get in touch
                </h5>
                <ul>
                    <li> <b>Email Support: </b>    {help.contact.emailSupport}</li>
                    <li> <b>Community Forums: </b>    {help.contact.communityForums}</li>
                </ul>
                <h5>
                    We're Here to Help
                </h5>
                <ul>
                  <li>{help.helpMessage}</li>
                </ul>

            </div>
        </>
    )
}

export default Help