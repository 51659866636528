import React from 'react'
import { Grid, Box } from '@mui/material'
import Folder from '../../assets/images/BigFolder.png'
import File from '../../assets/images/FileIcon.png'


const GridView = ({ data, selectedItems, handleItemSelect, showCheckbox, setShowCheckbox, path, setPath }) => {

    return (
        <Box sx={{ marginTop: '2vmax', background: "#FCFCFC" }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {data.map((item, index) => (
                    <Grid
                        key={item.id}
                        item xs={6} md={3}
                        sx={{ maxWidth: '300px' }}
                        onMouseEnter={() => setShowCheckbox(item.id)}
                        onMouseLeave={() => setShowCheckbox(null)}
                    >
                        <div className="grid-item" style={{
                            border: '1px solid #0000001A', boxShadow: "0px 0px 2px 1px #0000001A", cursor: 'pointer',
                            ...(showCheckbox === item.id || selectedItems.includes(item.id)) && { background: '#92c8d1' }
                        }}>
                            <div className='d-flex align-items-center justify-content-center' style={{
                                border: '1px solid #0000001A',
                            }}>
                                {item.fileType !== 'dir' ?
                                    <img src={File} alt="" style={{ padding: "1vmax" }} />
                                    :
                                    <button style={{ border: 'none', background: 'transparent' }} onClick={() => setPath(prevPath => {
                                        const newPath = prevPath === '/' ? `${prevPath}${item.fileName}` : `${prevPath}/${item.fileName}`;
                                        return newPath;
                                    })}>
                                        <img src={Folder} alt="" style={{ padding: "0.4vmax" }} />
                                        {/* <img src={folder} alt="" width={30} style={{ width: "30px", height: "30px" }} /> */}
                                    </button>
                                }
                            </div>
                            <div className='p-2 d-flex align-items-center justify-content-between' style={{ border: '1px solid #0000001A' }}>
                                <h5 style={{ flex: '1', wordWrap: 'break-word', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item.fileName}</h5>
                                {(showCheckbox === item.id || selectedItems.includes(item.id)) ? (
                                    <input
                                        className='me-2'
                                        type="checkbox"
                                        checked={selectedItems.includes(item.id)}
                                        onChange={() => handleItemSelect(item.id)}
                                    />
                                ) :
                                    <input
                                        className='me-2'
                                        type="checkbox"
                                        style={{ visibility: 'hidden' }}
                                    />

                                }
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default GridView