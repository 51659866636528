import React from 'react'
import './SubscriptionCard.css'
import { useNavigate } from 'react-router-dom'
import {  SelectPlanAction } from '../../store/reducers/user.reducers'
import { useDispatch } from 'react-redux'
import { SubscriptionDetails } from '../../store/actions/user.actions'


const SubscriptionCard = ({ plan }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const selectPlan = () => {
        if (plan.planAmount === 0) {
            // dispatch(customerPurchasePlan(plan.planStripeId, undefined, navigate))
            // navigate("/dashboard"
            const obj = {
                "orderID": "",
                "subscriptionID": "",
                "facilitatorAccessToken": "",
                "paymentSource": "free"
            }
            dispatch(SubscriptionDetails(obj, navigate));
            return;
        }
        dispatch(SelectPlanAction(plan))
        setTimeout(() => {
            navigate('/payment')
        }, 300)
    }

    return (
        <section className='subscripton_card'>
            <h4><span class="larger-font mb-3">${plan.planAmount}</span> {plan.planType === 'MONTH' ? '/month' : '/year'} </h4>
            <h3>{plan.planName}</h3>
            <h6>{plan.planSpace} space</h6>
            {/* <ul>                      // May be used Later
                <li>Lorem ipsum</li>
                <li>Lorem ipsum</li>
                <li>Lorem ipsum</li>
                <li>Lorem ipsum</li>
            </ul> */}
            <button className='subscribe-btn mt-3' onClick={selectPlan} >
                Get Started
            </button>
        </section>
    )
}

export default SubscriptionCard