import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { autoLogin } from '../store/actions/user.actions';
// Import your autoLogin action

const useAutoLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    useEffect(() => {
        const autoLoginUser = async () => {
            try {
                // Dispatch autoLogin action to attempt auto-login
        
                dispatch(autoLogin(navigate));

                // If auto-login succeeds, set isLoading to false
            } catch (error) {
                // Handle auto-login failure
                console.error('Auto-login error:', error);
                // Navigate to login page
                navigate('/login');
            }
        };
        autoLoginUser();
        // Clean up function
        return () => {
            // You can perform any cleanup here if needed
        };
    }, [dispatch, navigate]); // Run effect only once on component mount

    return { isLoading };
};

export default useAutoLogin;
