import React, { useEffect, useState } from 'react';
import loginImage from '../../assets/images/Drone_Image.png';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import './Login.css'
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/actions/user.actions';
import Loader from '../../components/Loader/Loader';
import eye from '../../assets/images/eye.png'
import hide from '../../assets/images/hide.png'
import useAuthRedirect from '../../hooks/useAuthRedirect';
import { emailPattern } from '../../constant/app-constants';

const Login = () => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [showPassword, setShowPassword] = useState(false); // State to track whether password is shown or hidden

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const { loading, isAuthenticated } = useSelector((state) => state.user);

    const onSubmit = (data) => {
        const { email, password } = data;
        dispatch(loginUser(email, password, navigate))
        reset();
    };

    return (
        <div className='Login container-fluid'>
            {loading && <Loader />}
            <div className="row">

                <div className="col-md-5" style={{ margin: 0, padding: 0 }}>
                    <img className='image custom-image' src={loginImage} alt="" />
                </div>
                <div className="col-md-6">
                    <div className="login-form">
                        <div className="heading mb-4">
                            <h1> Welcome back!</h1>
                            <h5>Login to continue your aesthetic journey</h5>
                        </div>
                        <div className="row col-md-9 mt-4">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="col-md-12 mt-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        {...register('email', {
                                            required: "Email is required",
                                            pattern: {
                                                value: emailPattern,
                                                message: "Invalid Email"
                                            },
                                        })}
                                    />
                                    {errors.email && <p className="text-danger">{errors.email.message}</p>}
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="input-group">
                                        <input
                                            // type="password"
                                            type={showPassword ? 'text' : 'password'} // Conditional rendering based on showPassword state
                                            className="form-control"
                                            placeholder="Password"
                                            {...register('password', { required: true, minLength: 8 })}
                                            aria-describedby="basic-addon2"
                                        />

                                        <button className='append-btn' type='button' onClick={() => setShowPassword(!showPassword)}>{showPassword ?
                                            <img src={eye} alt="" srcset="" />
                                            : <img src={hide} alt="" srcset="" />
                                        }</button>
                                    </div>

                                    <div>
                                        {errors.password && errors.password.type === "required" && (
                                            <p className="text-danger">Password is required</p>
                                        )}
                                        {errors.password && errors.password.type === "minLength" && (
                                            <p className="text-danger">Password must be at least 8 characters long</p>
                                        )}
                                    </div>


                                </div>
                                <div className="col-md-12 d-flex justify-content-between rememeber-box mb-3">
                                    <div className="form-check mt-2">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                        />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                                    </div>
                                    <Link to='/forgetPassword' className='forget-password mt-2'>Forget password?</Link>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <button className="btn btn-primary btn-purple" type="submit" style={{ width: "100%" }}>Login</button>
                                    <div className="rememeber-box text-center mt-3" style={{ color: "#3D3D3D80" }}>
                                        Don’t have an account? <Link to='/register' className='forget-password mt-2'>Sign up</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
