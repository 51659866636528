import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PaymentSuccess from '../../assets/images/PaymentSuccess.png'
import PaymentFailure from '../../assets/images/PaymentFailure.png'
import { useNavigate } from 'react-router-dom';
import { ClosePaymentDialog } from '../../store/reducers/user.reducers';
import './ConfirmPaymentDailog.css'

const ConfirmPaymentDailog = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isOpen = useSelector((state) => state.user.ConfirmPaymentDailog.isOpen);
    const isError = useSelector((state) => state.user.ConfirmPaymentDailog.isError);

    const onClose = () => {
        if (isError) {
            dispatch(ClosePaymentDialog())
            navigate('/subscripiton-plan')
        } else {
            dispatch(ClosePaymentDialog())
            navigate('/dashboard')
        }
    }

    const PaymentSuccessContent = (
        <DialogContent className='payment-dailog-content' >
            <div>
                <img src={PaymentSuccess} alt="" srcset="" />
            </div>
            <h3>
                Payment successful!
            </h3>
            <p>
                Thank you for purchasing.
            </p>
        </DialogContent>
    )

    const PaymentFailureContent = (
        <DialogContent className='payment-dailog-content' >
            <div>
                <img src={PaymentFailure} alt="" srcset="" />
            </div>
            <h3>
                Payment unsuccessful!
            </h3>
            <p>
                Something went wrong. We couldn't process your payment.
            </p>
        </DialogContent>
    )

    return (
        <Dialog open={isOpen} sx={{ minWidth: '300px' }} >
            {isError ?
                PaymentFailureContent
                :
                PaymentSuccessContent
            }
            <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                {isError ?
                    <button onClick={onClose} className='closebtn'  >Try Again</button>
                    :
                    <button onClick={onClose} className='closebtn' >Continue</button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmPaymentDailog;