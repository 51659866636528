import React from 'react';
import { useForm } from 'react-hook-form';
import './ForgetPassword.css';
import Header from '../../components/Header/Header';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { forgetPasswordLink } from '../../store/actions/user.actions';
import { emailPattern } from '../../constant/app-constants';

const ForgetPassword = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const { loading } = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const onSubmit = (data) => {
        // Handle form submission
        dispatch(forgetPasswordLink(data.email))
        reset()

    };

    return (
        <>
            <Header />
            <div className='forgetPassword-container'>
                {loading && <Loader />}
                <div className="box">
                    <h1>Forgot Password?</h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-md-12 mt-3">
                            <input
                                type="text"
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                placeholder='Email'
                                {...register('email', { required: 'Email is required', pattern: { value: emailPattern, message: 'Invalid email' } })}
                            />
                            {errors.email && <p className="text-danger">{errors.email.message}</p>}
                            <p style={{ marginTop: '10px' }}>
                                We’ll send a verification code to this email if it matches an existing account.
                            </p>
                        </div>

                        <div className="col-md-12 mt-4">
                            <button className="btn btn-primary btn-purple" type="submit" style={{ width: "100%" }}>Next</button>
                        </div>

                        <div className="col-md-12 mt-2">
                            <Link to='/login'>
                                <button className="btn btn-primary btn-white" type="button" style={{ width: "100%" }}>Back</button>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ForgetPassword;
