import axios from "axios";
import sha1 from "sha1";
import { API_URL } from "../constant/app-constants";
import { calculateSHA1, encodeToUtf8 } from "./pipes";

// initializing axios
const api = axios.create({
    baseURL: API_URL + "/api/v1",
})

// original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
export class Uploader {
    constructor(options, userId, absolutePath, onUploadComplete) {

        // this must be bigger than or equal to 5MB,
        // otherwise AWS will respond with:
        // "Your proposed upload is smaller than the minimum allowed size"

        this.userId = userId
        // this.chunkSize = options.chunkSize || 1024 * 1024 * 50
        this.chunkSize = Math.max(options.chunkSize || 1000 * 1000 * 5, 1000 * 1000 * 5);
        // number of parallel uploads
        this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15)
        this.file = options.file
        this.fileName = options.fileName
        this.aborted = false
        this.uploadedSize = 0
        this.progressCache = {}
        this.activeConnections = {}
        this.parts = []
        this.uploadedParts = []
        this.fileId = null
        this.fileKey = null
        this.onProgressFn = () => { }
        this.onErrorFn = () => { }
        this.count = 0;
        this.fileSize = options.file.size
        this.absolutePath = absolutePath
        this.shaChunk = [];
        this.uploadedChunkSize = 0;
        this.isUploadCompleted = false;
        this.onUploadComplete = onUploadComplete;
    }

    start() {
        this.initialize()
    }

    async initialize() {
        try {
            // adding the the file extension (if present) to fileName
            let fileName = this.fileName
            const ext = this.file.name.split(".").pop()
            if (ext) {
                fileName += `.${ext}`
            }

            // initializing the multipart request
            const videoInitializationUploadInput = {
                name: fileName,
                userId: this.userId,
                path: this.file.path,
                absolutePath: this.absolutePath
            }
            const initializeReponse = await api.request({
                url: "/uploads/initializeMultipartUpload",
                method: "POST",
                data: videoInitializationUploadInput,
            })

            const AWSFileDataOutput = initializeReponse.data

            this.fileId = AWSFileDataOutput.fileId
            this.fileKey = AWSFileDataOutput.fileKey

            // retrieving the pre-signed URLs
            // console.log(this.fileId, this.fileKey)

            let remainingBytes = this.file.size;
            let startByte = 0;

            while (remainingBytes > 0) {
                // Create a chunk with the minimum size or remaining bytes, whichever is less
                let chunkSize = Math.min(this.chunkSize, remainingBytes);

                // If remaining bytes are less than the minimum size, adjust chunk size
                if (remainingBytes < (2 * this.chunkSize)) {
                    chunkSize = remainingBytes;
                }


                const part = {
                    PartNumber: this.parts.length + 1,
                    signedUrl: null, // Placeholder for pre-signed URL
                    authorizationToken: null, // Placeholder for authorization token
                    chunkSize: chunkSize,
                };
                this.parts.push(part);
                // console.log(this.parts)

                remainingBytes -= chunkSize;
                startByte += chunkSize;
            }


            const AWSMultipartFileDataInput = {
                fileId: this.fileId,
                fileKey: this.fileKey,
                parts: this.parts.length,
                userId: this.userId,
                fileSize: this.fileSize
            }
            // console.log(AWSMultipartFileDataInput);
            const urlsResponse = await api.request({
                url: "/uploads/getMultipartPreSignedUrls",
                method: "POST",
                data: AWSMultipartFileDataInput,
            });

            let newParts = urlsResponse.data.parts;
            newParts.sort((a, b) => a.PartNumber - b.PartNumber);

            // Assign pre-signed URLs and authorization tokens to respective parts
            for (let i = 0; i < newParts.length; i++) {
                // console.log(newParts[i])
                this.parts[i].signedUrl = newParts[i].signedUrl;
                this.parts[i].authorizationToken = newParts[i].authorizationToken;
            }


            // console.log(this.parts)
            this.sendNext()
        } catch (error) {
            console.log(error)
            await this.complete(error)
        }
    }

    sendNext() {
        const activeConnections = Object.keys(this.activeConnections).length

        if (activeConnections >= this.threadsQuantity) {
            return
        }

        if (!this.parts.length) {
            if (!activeConnections) {
                this.complete()
            }

            return
        }


        const part = this.parts.shift()
        if (this.file && part) {
            const startByte = this.uploadedChunkSize; // bytes to upload 
            // const endByte = Math.min(startByte + part.chunkSize, file.size);
            const endByte = startByte + part.chunkSize;
            const chunk = this.file.slice(startByte, endByte);
            // console.log(startByte, endByte, part.PartNumber, chunk)
            this.uploadedChunkSize = endByte;


            const sendChunkStarted = () => {
                this.sendNext()
            }

            this.sendChunk(chunk, part, sendChunkStarted)
                .then(() => {
                    this.sendNext()
                })
                .catch((error) => {
                    console.log(error)
                    this.parts.push(part)
                    this.complete(error)
                })
        }
    }

    async complete(error) {
        if (error && !this.aborted) {
            this.onErrorFn(error)
            return
        }

        if (error) {
            this.onErrorFn(error)
            return
        }

        try {
            await this.sendCompleteRequest()
        } catch (error) {
            this.onErrorFn(error)
        }
    }

    async sendCompleteRequest() {
        if (this.fileId && this.fileKey) {
            // Calculate SHA1 hashes for uploaded parts
            // const sha1Array = this.uploadedParts.map((part) => {
            //     const chunk = this.file.slice((part.PartNumber - 1) * part.chunkSize, part.PartNumber * part.chunkSize);
            //     console.log(chunk)
            //     return calculateSHA1(chunk);
            // });

            const videoFinalizationMultiPartInput = {
                fileId: this.fileId,
                fileKey: this.fileKey,
                // parts: sha1Array, // Use SHA1 hashes of uploaded parts
                fileSize: this.fileSize,
                absolutePath: this.absolutePath,
                partData: this.shaChunk,
                userId: this.userId
            };

            const response = await api.request({
                url: "/uploads/finalizeMultipartUpload",
                method: "POST",
                data: videoFinalizationMultiPartInput,
            });
            if (typeof this.onUploadComplete === 'function') {
                this.onUploadComplete();
            }

        }
    }

    sendChunk(chunk, part, sendChunkStarted) {
        return new Promise((resolve, reject) => {
            this.upload(chunk, part, sendChunkStarted)
                .then((status) => {
                    if (status !== 200) {
                        reject(new Error("Failed chunk upload"))
                        return
                    }
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    handleProgress(part, event) {
        if (this.file) {
            if (event.type === "progress" || event.type === "error" || event.type === "abort") {
                this.progressCache[part] = event.loaded
            }

            if (event.type === "uploaded") {
                this.uploadedSize += this.progressCache[part] || 0
                delete this.progressCache[part]
            }

            const inProgress = Object.keys(this.progressCache)
                .map(Number)
                .reduce((memo, id) => (memo += this.progressCache[id]), 0)

            const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

            const total = this.file.size

            const percentage = Math.round((sent / total) * 100)
            // console.log(percentage)
            if (percentage === 100) {
                this.count = this.count + 1;
            }

            this.onProgressFn({
                fileName: this.fileName,
                sent: sent,
                total: total,
                percentage: percentage,
                file: this.file,
                count: this.count
            })
        }
    }

    upload(file, part, sendChunkStarted) {
        return new Promise((resolve, reject) => {
            if (this.fileId && this.fileKey) {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);

                // const chunkSize = part.chunkSize;
                // const startByte = this.uploadedChunkSize; // bytes to upload 
                // // const endByte = Math.min(startByte + part.chunkSize, file.size);
                // const endByte = startByte + part.chunkSize;
                // const chunk = file.slice(startByte, endByte);
                reader.onload = async (event) => {
                    const chunkContent = event.target.result;
                    // console.log(chunkContent, '--------------->')
                    // const sha1Hash = sha1(chunkContent); // Calculate the SHA-1 hash of the chunk content
                    const sha1Hash = await calculateSHA1(chunkContent);
                    // console.log(sha1Hash)
                    const xhr = (this.activeConnections[part.PartNumber - 1] = new XMLHttpRequest());
                    sendChunkStarted();

                    const progressListener = this.handleProgress.bind(this, part.PartNumber - 1);

                    xhr.upload.addEventListener("progress", progressListener);
                    xhr.addEventListener("error", progressListener);
                    xhr.addEventListener("abort", progressListener);
                    xhr.addEventListener("loadend", progressListener);

                    xhr.open("POST", part.signedUrl);
                    xhr.setRequestHeader('Authorization', part.authorizationToken);
                    xhr.setRequestHeader('X-Bz-File-Name', encodeURIComponent(this.fileName));
                    xhr.setRequestHeader('Content-Type', 'b2/x-auto');
                    xhr.setRequestHeader('X-Bz-Part-Number', part.PartNumber);
                    xhr.setRequestHeader('X-Bz-Content-Sha1', sha1Hash);

                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4 && xhr.status === 200) {
                            // console.log(xhr,"xhrrrrrrrrrrr");
                            // const ETag = xhr.getResponseHeader("ETag");
                            // if (ETag) {
                            // const uploadedPart = {
                            //     PartNumber: part.PartNumber,
                            //     sha1: part.sha1Hash
                            // };
                            const responseText = xhr.responseText; // Capture the response body as text
                            // Parse the response based on its format (JSON, XML, etc.)
                            const responseData = JSON.parse(responseText); // Assuming JSON format
                            // console.log("Response:", responseData);

                            let chunkData = {
                                partNumber: responseData.partNumber,
                                sha1: responseData.contentSha1,
                            }
                            this.uploadedParts.push(chunkData);
                            this.shaChunk.push(chunkData)

                            resolve(xhr.status);
                            delete this.activeConnections[part.PartNumber - 1];
                            // }
                        }
                    };

                    xhr.onerror = (error) => {
                        reject(error);
                        delete this.activeConnections[part.PartNumber - 1];
                    };

                    xhr.onabort = () => {
                        reject(new Error("Upload canceled by user"));
                        delete this.activeConnections[part.PartNumber - 1];
                    };

                    xhr.send(chunkContent); // Send the chunk content
                };

                // Read the chunk as binary string
            }
        });
    }


    onProgress(onProgress) {
        this.onProgressFn = onProgress
        return this
    }

    onError(onError) {
        this.onErrorFn = onError
        return this
    }

    abort() {
        Object.keys(this.activeConnections)
            .map(Number)
            .forEach((id) => {
                this.activeConnections[id].abort()
            })

        this.aborted = true
    }
}
