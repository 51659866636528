import { Box, Breadcrumbs, Link, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import './PaymentHistory.css'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, styled } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell'
import { useDispatch, useSelector } from 'react-redux';
import { GetPaymentHistory } from '../../../../store/actions/user.actions';
import Loader from '../../../../components/Loader/Loader'
import { calculateNextDate, formateDateToAbbreviatedMonthDate, months } from '../../../../Utils/pipes';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#6771da1a !important',
        fontSize: 20
        //   color: theme.palette.common.white,
    },
}));

const PaymentHistory = () => {

    const dispatch = useDispatch();
    const paymentHistory = useSelector((state) => state.user.paymentHistory)
    const loading = useSelector((state) => state.user.loading)
    const isMobileScreen = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        dispatch(GetPaymentHistory())
    }, [])

    return (
        <div className='payment-history'>
            {loading && <Loader />}
            <Breadcrumbs aria-label="breadcrumb" sx={{ '& .MuiBreadcrumbs-separator': { fontSize: '25px' }, fontFamily: 'Montserrat' }}>
                <Link underline="hover" color="inherit">
                    <h3 className='text-secondary' style={{ margin: 0, padding: 0, }} >Settings</h3>
                </Link>
                <h3 style={{ margin: 0, padding: 0, color: 'black' }}  >PaymentHistory</h3>
            </Breadcrumbs>

            <div className='col-md-12 row mt-4' style={{ background: '#FBFBFB', boxShadow: '0px 2px 6px 4px #0000000D' }} > { /* background: #FBFBFB; */}
                <h3 >Current Plan</h3>
                <div className='d-flex align-items-baseline'>
                    <h3 className='me-3 voilet fw-bold'>{paymentHistory && paymentHistory.planDetail.planName}</h3>
                    <h6>{paymentHistory && paymentHistory.planDetail.planSpace}</h6>
                </div>
                <p className="text-secondary">{paymentHistory && (paymentHistory.planDetail.planType === 'MONTH' ? 'Billed monthly' : 'Billed yearly' )}</p>
                <hr />
                <div>
                    <div className='mb-4 d-flex align-items-center justify-content-between'>
                        <h4 className='voilet'>Total {paymentHistory && (paymentHistory.planDetail.planType === 'MONTH' ? 'Billed monthly' : 'Billed yearly' )} price</h4>
                        <h4> {paymentHistory && (paymentHistory.planDetail.planAmount.toFixed(2))}$ </h4>
                    </div>
                    {(paymentHistory && paymentHistory.planDetail.planType !== 'LifeTime') && <h4 className='mb-3'>Billing Details:</h4>
                    }
                    {/* <div className='d-flex align-items-center justify-content-between mb-2'>
                        <h5>Billing cycle <span>(switch to monthly)</span></h5>
                        <h5>Annual <span>(Next Billing Date {paymentHistory && formateDateToAbbreviatedMonthDate(paymentHistory.planDetail.current_period_end)}) </span></h5>
                    </div> */}
                    {
                        (paymentHistory && paymentHistory.planDetail.planType !== 'LifeTime') &&
                        <div className='d-flex align-items-center justify-content-between mb-2 flex-wrap'>
                            <h5>Billing contact </h5>
                            <h5 style={{ wordBreak: 'break-word' }}>{paymentHistory && paymentHistory.transaction && paymentHistory.transaction.transactions[0].payer_email} </h5>
                        </div>
                    }
                </div>
                <hr className='mb-4' />
                {(paymentHistory && paymentHistory.planDetail.planType !== 'LifeTime') && <h3 className='mb-3'>Payment History</h3>}
                {(paymentHistory && paymentHistory.planDetail.planType !== 'LifeTime') &&
                    <TableContainer component={Paper} sx={{ overflowX: 'auto', width: isMobileScreen ? '90vw' : '100%', background: "#FCFCFC" }} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Subscription Cycle </StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Payment status</StyledTableCell>
                                    <StyledTableCell>Date</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paymentHistory.transaction.transactions.map((item) => (
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={item.id} >
                                        <TableCell>
                                            {formateDateToAbbreviatedMonthDate(item.time)} - {calculateNextDate(item.time, paymentHistory.planDetail.planType)}
                                        </TableCell>
                                        <TableCell>
                                            {item.amount_with_breakdown.gross_amount.value} $
                                        </TableCell>
                                        <TableCell>
                                            <span className='status'>
                                                {item.status}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            {formateDateToAbbreviatedMonthDate(item.time)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </div>
        </div >
    )
}

export default PaymentHistory

