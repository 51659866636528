import React, { useEffect } from 'react'
import dropImage from "../../assets/images/pngwing.png"
import companyLogo from "../../assets/images/companyLogo.png"
import './Splash.css'
import { useNavigate } from 'react-router-dom'
import CompanyLogo from '../../components/CompanyLogo/CompanyLogo'

const Splash = () => {

    const navigte = useNavigate()
    useEffect(() => {
        // Define the duration (in milliseconds) for the splash screen to be displayed
        const splashDuration = 5000; // 5 seconds
        // Set a timeout to navigate to a different screen after the specified duration
        const timer = setTimeout(() => {
            // Navigate to the desired screen
            navigte('/login');
        }, splashDuration);
        // Clean up the timeout when the component is unmounted or the effect is re-run
        return () => clearTimeout(timer);
    }, [navigte]);

    return (
        <div className='splash-screen'>

            <h1>
                Sync for Dji
            </h1>
            <CompanyLogo />
            <div>
                <img src={dropImage} alt="" srcset="" style={{width:"100%"}}/>
            </div>
        </div>
    )
}

export default Splash