import React, { useState } from 'react'
import Loader from '../../components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { emailPattern } from '../../constant/app-constants';
import eye from '../../assets/images/eye.png'
import hide from '../../assets/images/hide.png'
import { DeleteUserProfile } from '../../store/actions/user.actions';
import { useMediaQuery } from '@mui/material';

const DeleteUser = () => {

    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const { loading } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false);
    const isMobileScreen = useMediaQuery('(max-width:600px)')

    const onSubmit = (data) => {
        // Handle form submission
        // dispatch(ChangePasswordAction(token, data.password, navigate))
        dispatch(DeleteUserProfile(data.email, data.password))
        reset()
    };

    return (
        <div className='forgetPassword-container' style={{padding:"1vmax"}}>
            {loading && <Loader />}
            <div className="box" style={{ minWidth: isMobileScreen ? '90vw' : '600px' }}>
                <h1>Delete Your Account</h1>
                <p>If you delete your account, you will permanently lose all your data. This action cannot be undone.</p>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="col-md-12 mt-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            {...register('email', {
                                required: "Email is required",
                                pattern: {
                                    value: emailPattern,
                                    message: "Invalid Email"
                                },
                            })}
                        />
                        {errors.email && <p className="text-danger">{errors.email.message}</p>}
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="input-group">
                            <input
                                type={showPassword ? 'text' : 'password'} // Conditional rendering based on showPassword state
                                className="form-control"
                                placeholder="Password"
                                {...register('password', { required: true, minLength: 8 })}
                            />
                            <button className='append-btn' type='button' onClick={() => setShowPassword(!showPassword)}>{showPassword ?
                                <img src={eye} alt="" srcset="" />
                                : <img src={hide} alt="" srcset="" />
                            }</button>
                        </div>

                        {errors.password && errors.password.type === "required" && (
                            <p className="text-danger">Password is required</p>
                        )}
                        {errors.password && errors.password.type === "minLength" && (
                            <p className="text-danger">Password must be at least 8 characters long</p>
                        )}
                    </div>

                    <div className="col-md-12 mt-4">
                        <button className="btn btn-primary btn-purple" type="submit" style={{ width: "100%" }}>Delete Account   </button>
                    </div>
                    {/* 
                <div className="col-md-12 mt-2">
                    <Link to='/login'>
                        <button className="btn btn-primary btn-white" type="button" style={{ width: "100%" }}>Back</button>
                    </Link>
                </div> */}
                </form>
            </div>
        </div>
    )
}

export default DeleteUser