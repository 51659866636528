import React from 'react'
import { Breadcrumbs, Link } from '@mui/material';
import home from '../../assets/images/Home.png';
import "./Breadcrumb.css"

const Breadcrumb = ({ path, onClick }) => {

    const handleClick = (event, newPath) => {
        event.preventDefault();
        const index = path.lastIndexOf(newPath);
        const result = path.substring(0, index + newPath.length);
        onClick(result);
    };
    const handleHomeClick = (event) => {
        event.preventDefault();
        onClick("/");
    };

    const pathArray = path === "/" ? [] : path.split('/').filter(Boolean);

    return (
        <div className='mt-3 mb-3'>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href="#"
                    onClick={handleHomeClick}
                >
                    <img src={home} alt="" style={{
                        width: "15px",
                        marginBottom: "5px"
                    }} />
                </Link>
                {pathArray.map((item, index) => (
                    <span key={index}>
                        <Link
                            color="inherit"
                            href="#"
                            onClick={(event) => handleClick(event, item)}
                        >
                            {item}
                        </Link>
                        {/* {index < pathArray.length - 1 && <span>/</span>} */}
                    </span>
                ))}
            </Breadcrumbs>
        </div>

    );
}

export default Breadcrumb