import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import CircularProgressWithLabel from '../CircularProgressWithLabel/CircularProgressWithLabel';
import { useSelector } from 'react-redux';

export default function DownloadNotification() {
    const open = useSelector((state) => state.upload.isDownloadNotificationOpen);
    const downloads = useSelector((state) => state.upload.downloadingProgress);

    const getVerticalOffset = (index) => {
        return index * 60; // Adjust this value as needed for proper spacing
    };

    return (
        <div>
            {downloads.map((item, index) => (
                <Snackbar
                    key={item.id}
                    open={open}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    style={{ bottom: `${getVerticalOffset(index)}px` }}
                    message={`Downloading...`}
                    action={
                        <React.Fragment>
                            <CircularProgressWithLabel size={40} value={item.progress} color="primary" />
                        </React.Fragment>
                    }
                />
            ))}
        </div>
    );
}
