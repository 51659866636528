import * as React from 'react';
import './DataTable.css';
import folder from '../../assets/images/Folder.png'
import file from '../../assets/images/smallFileIcon.png'
import morevertical from '../../assets/images/more-vertical.png'
import { formatDate } from '../../Utils/pipes';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Paper, useMediaQuery } from '@mui/material';

export default function DataTable({ data, selectedItems, handleItemSelect, showCheckbox, setShowCheckbox, path, setPath }) {

    // Define column headers
    const columns = ['', '', 'Name', 'Size', 'Last Modified', 'File Type'];
    const isMobileScreen = useMediaQuery('(max-width:600px)')

    return (
        <TableContainer component={Paper} sx={{ overflowX: 'auto', width: isMobileScreen ? '90vw' : '100%', background: "#FCFCFC" }} >
            <Table sx={{ minWidth: 700 }} aria-label="customized table" >
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell key={index}>{column}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item) => (
                        <TableRow
                            key={item.id}
                            onMouseEnter={() => setShowCheckbox(item.id)}
                            onMouseLeave={() => setShowCheckbox(null)}
                            sx={{
                                ...(showCheckbox === item.id || selectedItems.includes(item.id)) && { bgcolor: '#92c8d1' }
                            }}
                        >
                            <TableCell className='check'>
                                <Checkbox
                                    checked={selectedItems.includes(item.id)}
                                    onChange={() => handleItemSelect(item.id)}
                                    style={(showCheckbox === item.id || selectedItems.includes(item.id)) ? {} : { visibility: 'hidden' }}
                                />
                            </TableCell>
                            <TableCell>
                                {item.fileType !== 'dir' ? (
                                    <img src={file} alt="" style={{ width: "30px", height: "30px" }} />
                                ) : (
                                    <button className='fileBtn' onClick={() => setPath(prevPath => {
                                        const newPath = prevPath === '/' ? `${prevPath}${item.fileName}` : `${prevPath}/${item.fileName}`;
                                        return newPath;
                                    })}>
                                        <img src={folder} alt="" width={30} style={{ width: "30px", height: "30px" }} />
                                    </button>
                                )}
                            </TableCell>
                            <TableCell>{item.fileName}</TableCell>
                            <TableCell>{item.fileSize}</TableCell>
                            <TableCell>{formatDate(item.updatedAt)}</TableCell>
                            <TableCell>{item.fileType}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}